<template>
  <v-card flat>
    <v-card-text
      ><div class="text-to-print">
        <component :is="textType" :doc="document" />
        <v-card v-if="role > 1">
          <v-card-title>Контактная информация</v-card-title>
          <v-card-subtitle>Контрагента:</v-card-subtitle>
          <v-card-text>
            Телефон: {{ document.partner.telephone }}<br />
            E-mail: {{ document.partner.email }}
          </v-card-text>
          <v-card-text></v-card-text>
        </v-card>
        <v-card v-if="role > 1" class="show-on-print">
          <v-card-title>Подпись</v-card-title>
          <v-card-text>
            Заявление подано электронно через портал sibseti.su
          </v-card-text>
          <v-card-text></v-card-text>
        </v-card></div
    ></v-card-text>
    <v-card-actions>
      <v-btn outlined small dark color="blue" @click.prevent="printText">
        <v-icon>
          mdi-printer
        </v-icon>
        Распечатать заявку
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Person15 from './ConnectionForm/Person15';
import Simple150 from './ConnectionForm/Simple150';
import SimpleForm from './ConnectionForm/Simple';
import TempForm from './ConnectionForm/Temp';

export default {
  name: 'ConnectionDocumentText',
  components: {
    'person-15-form': Person15,
    'simple-150-form': Simple150,
    'simple-form': SimpleForm,
    'temp-form': TempForm
  },
  props: ['document'],
  data: () => ({
    textType: 'temp-form'
  }),
  watch: {
    document(value) {
      switch (value.type) {
        case 1:
          this.textType = 'temp-form';
          break;
        case 2:
          this.textType = 'simple-form';
          break;
        case 3:
          this.textType = 'simple-150-form';
          break;
        case 4:
          this.textType = 'person-15-form';
          break;
      }
    }
  },
  computed: {
    role() {
      return this.$store.getters.getRole;
    }
  },
  mounted() {
    switch (this.document.type) {
      case 1:
        this.textType = 'temp-form';
        break;
      case 2:
        this.textType = 'simple-form';
        break;
      case 3:
        this.textType = 'simple-150-form';
        break;
      case 4:
        this.textType = 'person-15-form';
        break;
    }
  },
  methods: {
    printText() {
      window.print();
    }
  }
};
</script>

<style scoped>
.show-on-print {
  display: none;
}
@media print {
  .show-on-print {
    display: block;
  }
  .text-to-print {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    z-index: 9999999;
  }
}
</style>
