<template>
  <v-container>
    <v-row v-if="selected">
      <v-col>
        <operations-window :id="selected._id" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined tile>
          <v-card-title>Технологические присоединения</v-card-title>
          <v-data-table
            :headers="headers"
            :items="connections"
            item-key="_id"
            :loading="loading"
            loading-text="Идет загрузка... Пожалуйста подожите"
          >
            <template #[`item.status`]="{ item }">
              <v-chip :color="getColor(item.status)" dark>
                {{ statusText[item.status] }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                class="mr-2"
                color="primary"
                @click="selected = item"
              >
                Открыть
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable standard/no-callback-literal */
import { mapState } from "vuex";
import { CONNECTION_STATUS } from '../utils/mainConsts';

import OperationsWindow from '../components/documents/provider/OperationsWindow';

const PATH = process.env.VUE_APP_GRAPHQL;

export default {
  name: "Provider",
  components: {
    'operations-window': OperationsWindow
  },
  data: () => ({
    loading: false,
    headers: [
      { text: 'Потребитель', value: 'partner.name' },
      { text: 'Статус', value: 'status' },
      { text: 'Действия', value: 'actions' },
    ],
    selected: null,
    connections: [],
    statusText: CONNECTION_STATUS,
  }),
  computed: {
    token() {
      return localStorage.getItem('token');
    },
    role() {
      return this.$store.state.role;
    },
    ...mapState({ universal: state => state.univarsal }),
  },
  mounted() {
    this.fetchDocuments();
  },
  methods: {
    async fetchDocuments() {
      const graphqlQuery = {
        query: `
          {
            connectionFetchAll {
              ${this.universal.defaultDataToFetch.connection}
            }
          }
        `
      };
      this.loading = true;
      try {
        const response = await fetch(PATH, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token,
          },
          body: JSON.stringify(graphqlQuery)
        });
        const responseData = await response.json();
        this.connections = responseData.data.connectionFetchAll;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    openDocument(item) {
      this.$router.push(`/provider/${item._id}`);
    },

    getColor(status) {
      switch (status) {
        case 6:
          return 'red';
        case 7:
          return 'orange';
        case 8:
          return 'green';
        default:
          return 'grey';
      }
    }
  }
}
</script>

<style scoped>
.root {
  margin: 10px;
}
</style>
